import React, { useCallback, useEffect, useMemo, useState } from "react";

import Header from "../../../../components/blocks/header";
import SearchQuery from "../../../../components/forms/searchQuery";
// import ClaimsTable from "../../../../components/tables/claimsTable";
import ProductTable, { ProductTableColumn } from "../exodus/ProductTable";
import applyFilters from "../../../../helpers/urlState";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Hospital } from "../../../../ts-utils/types";
import { useGlobalContext } from "../../../../contexts/globalContext";
import TableButton from "../../../../components/blocks/tableButton";
import {
  useConfirmClaim,
  useNerveClaims,
} from "../../../../hooks/nerve/useNerve";
import ConfirmClaim from "./confirmClaims";
import LoadingButton from "../../../../components/common/loadingButton";

const NerveClaims = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const pathname = location.pathname.replace("/", "");

  const [search, setSearch] = useState<React.SetStateAction<string>>(
    searchParams.get("search") || ""
  );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.currentTarget.value;

    setSearch(searchTerm);

    applyFilters(
      {
        page: 1,
        search: searchTerm,
      },
      navigate,
      pathname
    );
  };

  const { user, city } = useGlobalContext();

  const pageSize: number = 5;
  const [currentPage, setCurrentPage] = useState(1);
  // const filters = {
  //   user_id: user?.id as string,
  //   limit: pageSize,
  //   page: currentPage,
  //   name: search,
  // };

  const { claims, isFetching } = useNerveClaims(city);

  const paginatedData = useMemo(() => {
    const data = Array.isArray(claims) ? claims : [];

    const totalItems = data.length;

    const filteredData = data.filter((item: { name: string }) =>
      item.name.toLowerCase().includes((search as string).toLowerCase())
    );

    const totalFilteredItems = filteredData.length;

    // Calculate the start and end index for the current page
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    return {
      data: filteredData.slice(startIndex, endIndex),
      totalItems,
      totalFilteredItems,
      totalFilteredPages: Math.ceil(totalFilteredItems / pageSize),
    };
  }, [claims, currentPage, search]);

  const { data: paginated, totalFilteredPages } = paginatedData;

  const [confirmClaim, setConfirmClaim] = useState<{
    id: string;
    name: string;
  }>({
    id: "",
    name: "",
  });

  const [progress, setProgress] = useState(0);

  const handleProgressChange = (newProgress: number) => {
    setProgress(newProgress);
  };
  const [isConfirmed, setIsConfirmed] = useState(false);

  const { mutate } = useConfirmClaim(handleProgressChange);

  const onSubmit = () => {
    const payload = {
      id: confirmClaim.id,
      user_id: user?.id as string,
    };
    console.log(payload);
    mutate(payload, {
      onSuccess: () => {
        setIsConfirmed(false);
      },
    });
  };
  const handleConfirmClaim = (hospital: Hospital): void => {
    console.log(hospital);
    setConfirmClaim(hospital);
    setIsConfirmed(true);
  };

  function renderCell(hospital: Hospital, column: ProductTableColumn) {
    switch (column.accessor) {
      case "name":
        return (
          <>
            {hospital?.hospital_id ? (
              <Link
                className="text-[#49209F]  underline font-bold"
                to={`/hospitals/${hospital?.hospital_id}`}
              >
                {hospital?.name ?? "N/A"}
              </Link>
            ) : (
              <> {hospital?.name ?? "N/A"}</>
            )}
          </>
        );
      case "action":
        return (
          <TableButton
            buttonClass="status primary"
            onClick={(): void => handleConfirmClaim(hospital)}
            label="Process"
          />
        );

      default:
        return <span>{hospital[column.accessor]}</span>;
    }
  }
  const columns: ProductTableColumn[] = [
    { header: "ID", accessor: "id" },
    { header: "Hospital Name", accessor: "name" },
    { header: "Email Address", accessor: "email" },
    { header: "Phone Number", accessor: "phone" },
    { header: "Action", accessor: "action" },
  ];

  useEffect(() => {
    const pageFromUrl = parseInt(searchParams.get("page") || "1", 10);
    if (pageFromUrl !== currentPage) {
      setCurrentPage(pageFromUrl);
    }
  }, [location.search, searchParams, currentPage]);
  const goToPage = useCallback(
    (page: number) => {
      applyFilters(
        {
          page,
          search,
        },
        navigate,
        pathname
      );
      setCurrentPage(page);
    },
    [navigate, pathname, search]
  );

  return (
    <React.Fragment>
      <Header
        title={`Nerve Account Claims - ${paginatedData.totalFilteredItems}`}
      />

      <div className="hospitals-container">
        <div className="hospitals-container-top">
          <SearchQuery
            name="search"
            placeholder="Search"
            value={search}
            onChange={handleSearch}
          />
        </div>

        <div className="hospitals-container-bottom table-responsive">
          <div className="flex items-center justify-center">
            <LoadingButton
              visible={isFetching}
              message="Fetching information, please wait"
            />
          </div>
          {/* <ClaimsTable /> */}
          <>
            <ProductTable
              data={paginated}
              columns={columns}
              renderCell={renderCell}
              totalPages={totalFilteredPages}
              currentPage={currentPage}
              goToPage={goToPage}
              noDataMessage={
                "You Currently don't have any Nerve Account Claims"
              }
            />
          </>
        </div>
      </div>
      <ConfirmClaim
        onSubmit={onSubmit}
        progress={progress}
        selectedProspect={confirmClaim}
        isConfirmed={isConfirmed}
        setIsConfirmed={setIsConfirmed}
      />
    </React.Fragment>
  );
};

export default NerveClaims;
