import React, { useCallback, useEffect, useMemo, useState } from "react";

import HibernatedHospitalsTable from "../../../../components/tables/hibernatedLeadTable";

// import Button from "../common/button";
import Header from "../../../../components/blocks/header";
import UnsuccessFulPitchTable from "../../../../components/tables/unsuccessfulLeadTable";

import { SelectOption } from "../../../../ts-utils/interfaces";
import {
  useDisableAgent,
  useGetAllAgents,
} from "../../../../hooks/agent/useAgent";
import { useLocation, useNavigate } from "react-router-dom";
import applyFilters from "../../../../helpers/urlState";
import { SyncLoader } from "react-spinners";
import SearchQuery from "../../../../components/forms/searchQuery";
import { Button } from "../../../../@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../@/components/ui/select";
import * as Dialog from "@radix-ui/react-dialog";
import AddAgent from "./AddAgent";

import { useAddAgent } from "../../../../hooks/agent/useAgent";
import toastService from "../../../../services/toastService";
import ProductTable, { ProductTableColumn } from "../exodus/ProductTable";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../../@/components/ui/dropdown-menu";
import { MoreVertical, ShieldBan } from "lucide-react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../../../../@/components/ui/alert-dialog";
import { getPrivileges } from "../../../../services/authService";

const positionsOptions: SelectOption[] = [
  { id: "sales_lead", name: "Key Account Agent" },
  { id: "sales_rep", name: "Customer Acquisition Agent" },
];
const statusOptions: SelectOption[] = [
  { id: "1", name: "Disabled" },
  { id: "0", name: "Active" },
];
const ManageAgents = () => {
  const privileges = getPrivileges();
  const { mutateAddAgent, isPending: isAdding } = useAddAgent();
  const { mutateDisableAgent } = useDisableAgent();

  const location = useLocation();
  const pathname = location.pathname.replace("/", "");
  const navigate = useNavigate();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const limit = 10;
  const initialPageIndex = parseInt(searchParams.get("page") || "1", 10) - 1;

  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [active, setActive] = useState("1");
  const [isDisable, setIsDisable] = useState(false);
  const [status, setStatus] = useState("");
  const [position, setPosition] = useState("");
  const [currentPage, setCurrentPage] = useState(initialPageIndex + 1);
  const [selectedAgent, setSelectedAgent] = useState<{ id: string } | null>(
    null
  );

  const { agents, isPending, paginate } = useGetAllAgents(
    currentPage,
    limit,
    search,
    position,
    status
  );

  const checkActive = () => ({
    isFirstActive: active === "1",
    isSecondActive: active === "2",
    isThirdActive: active === "3",
  });

  const checkInitialPrivilege = () => {
    const initialPrivilege = privileges.viewAgents
      ? "1"
      : privileges.viewHibernated
      ? "2"
      : "3";

    setActive(initialPrivilege);
  };

  const { isFirstActive, isSecondActive, isThirdActive } = checkActive();

  useEffect(() => {
    checkInitialPrivilege();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const pageFromUrl = parseInt(searchParams.get("page") || "1", 10);
    if (pageFromUrl !== currentPage) {
      setCurrentPage(pageFromUrl);
    }
  }, [location.search, searchParams, currentPage]);

  const goToPage = useCallback(
    (page: number) => {
      applyFilters(
        {
          page,
          search,
          position,
        },
        navigate,
        pathname
      );
      setCurrentPage(page);
    },
    [navigate, pathname, search, position]
  );

  const handlePositionChange = (value: string) => {
    setPosition(value);
  };

  const onStatusChange = (value: string) => {
    setStatus(value);
  };

  const handleAddAgent = (item: any) => {
    setOpen(true);
  };

  const onSubmit = (data: {
    username: string;
    email: string;
    password: string;
    confirmPassword: string;
    position: string;
    city: string;
    country: string;
  }): void => {
    mutateAddAgent(data, {
      onSuccess: (data) => {
        toastService.success(data.data.message || "Agent created successfully");
        setOpen(false);
      },
    });
  };

  const handleDisable = async (): Promise<void> => {
    mutateDisableAgent(selectedAgent?.id);
  };

  function renderCell(agents: any, column: ProductTableColumn) {
    switch (column.accessor) {
      case "action":
        return (
          <div>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="h-8 w-8 p-0">
                  <span className="sr-only">Open menu</span>
                  <MoreVertical className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem
                  className="cursor-pointer flex items-center text-red-600"
                  onClick={() => {
                    setIsDisable(true);
                    setSelectedAgent(agents);
                  }}
                >
                  <ShieldBan className="mr-2 h-4 w-4" /> Disable
                </DropdownMenuItem>
                {/* {permission.createAgent && (
              <DropdownMenuItem
                className="cursor-pointer"
                onClick={() => {
                  handleViewEstimatedCost(user);
                  setEstimatedCost(true);
                }}
              >
                <Pencil className="mr-2 h-4 w-4" /> Approve Estimated Cost
              </DropdownMenuItem>
            )} */}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        );
      case "disable":
        return agents[column.accessor] === "1" ? (
          <span className="text-red-200 bg-red-600 px-2 p-1 rounded-full">
            Disabled
          </span>
        ) : (
          <span className="text-green-200 bg-green-600 px-2 p-1 rounded-full">
            Active
          </span>
        );
      case "conversion":
        return (
          <span>
            {agents[column.accessor] !== null
              ? `${agents[column.accessor]}%`
              : "0%"}
          </span>
        );
      case "position":
        return (
          <span>
            {agents[column.accessor] === "sales_lead"
              ? `Key Account Agent`
              : "Customer Acquisition Agent"}
          </span>
        );

      default:
        return <span>{agents[column.accessor] ?? "N/A"}</span>;
    }
  }

  const column: ProductTableColumn[] = [
    { header: "ID", accessor: "id" },
    { header: "Agent Name", accessor: "username" },
    { header: "Position", accessor: "position" },
    { header: "City", accessor: "city" },
    { header: "Country", accessor: "country" },
    { header: "Total Assigned Hospitals", accessor: "total_assigned" },
    { header: "Total Active Hospitals", accessor: "total_active" },
    { header: "Conversion Rate (%)", accessor: "conversion" },
    { header: "Status", accessor: "disable" },
    { header: "Action", accessor: "action" },
  ];

  return (
    <React.Fragment>
      {isPending && (
        <div className="fixed top-0 bottom-0 left-0 right-0 flex mx-auto items-center justify-center h-dvh z-30">
          <div className="bg-gray-700 absolute inset-0 opacity-50"></div>
          <SyncLoader
            color="#3B82F6"
            loading={isPending}
            className="relative"
            size={30}
          />
        </div>
      )}
      <Header title="Manage Agents" />

      <div className="lead-container mt-5 table-responsive">
        {privileges.viewAgents && isFirstActive && (
          <>
            <div className="agents-table-container-top ">
              <SearchQuery
                name="search"
                placeholder="Search"
                value={search}
                onChange={(e: any) => setSearch(e.currentTarget.value)}
              />

              <div className="right-options space-x-3">
                <Select
                  onValueChange={handlePositionChange}
                  defaultValue={position}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Filter By Agent Position" />
                  </SelectTrigger>
                  <SelectContent position="item-aligned">
                    {positionsOptions?.map(
                      (position: { id: string; name: string | number }) => (
                        <SelectItem key={position.id} value={position.id}>
                          {position.name}
                        </SelectItem>
                      )
                    )}
                  </SelectContent>
                </Select>{" "}
                <Select
                  onValueChange={onStatusChange}
                  // defaultValue={position}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Filter By Status" />
                  </SelectTrigger>
                  <SelectContent position="item-aligned">
                    {statusOptions?.map(
                      (status: { id: string; name: string | number }) => (
                        <SelectItem key={status.id} value={status.id}>
                          {status.name}
                        </SelectItem>
                      )
                    )}
                  </SelectContent>
                </Select>
                <Button
                  onClick={handleAddAgent}
                  variant="default"
                  className="px-5 !rounded-lg app-btn"
                >
                  Create Agent
                </Button>
              </div>
            </div>
            <div className="mx-auto px-4 md:px-8 mb-5">
              <>
                <ProductTable
                  data={agents}
                  columns={column}
                  renderCell={renderCell}
                  totalPages={paginate?.totalPages}
                  currentPage={currentPage}
                  goToPage={goToPage}
                  noDataMessage={"You Currently Don't Have Any Agents"}
                />
              </>
            </div>
          </>
        )}
        {privileges.viewHibernated && isSecondActive && (
          <HibernatedHospitalsTable search="" status="" />
        )}
        {privileges.viewUnsuccessfulPitches && isThirdActive && (
          <UnsuccessFulPitchTable />
        )}
      </div>
      <Dialog.Root open={open} onOpenChange={setOpen}>
        <Dialog.Portal>
          <Dialog.Overlay className="data-[state=open]:animate-overlayShow fixed inset-0 w-full h-full bg-black opacity-40" />
          <Dialog.Content
            aria-describedby={undefined}
            className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-full max-w-2xl mx-auto px-4"
          >
            <div className="bg-white rounded-md shadow-lg px-4 py-6 ">
              <div className="flex items-center justify-between mb-3">
                <Dialog.Title className="text-xl font-bold text-gray-800 text-center ">
                  Add Agent
                </Dialog.Title>
                <Dialog.Close className="p-2 text-gray-400 rounded-md hover:bg-gray-100">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5 mx-auto"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </Dialog.Close>
              </div>

              <div className="max-h-96 lg:max-h-full overflow-y-auto lg:overflow-y-hidden">
                <AddAgent onSubmit={onSubmit} isPending={isAdding} />
              </div>
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>

      <AlertDialog open={isDisable} onOpenChange={setIsDisable}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle className="capitalize">
              Are you absolutely sure?
            </AlertDialogTitle>
            <AlertDialogDescription className="capitalize">
              This action cannot be undone. This will permanently disable this
              Agent.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter className="flex items-center !justify-center">
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              className="mt-1.5 bg-red-500"
              onClick={handleDisable}
            >
              Continue
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </React.Fragment>
  );
};

export default ManageAgents;
