import React, { useCallback, useEffect, useMemo, useState } from "react";
import Header from "../../../components/blocks/header";
import pipelineService from "../../../services/pipelineService";
import toastService from "../../../services/toastService";
import { useGlobalContext } from "../../../contexts/globalContext";
import { SyncLoader } from "react-spinners";
import applyFilters from "../../../helpers/urlState";
import ProductTable, { ProductTableColumn } from "./exodus/ProductTable";
import { getPrivileges } from "../../../services/authService";
import { useLocation, useNavigate } from "react-router-dom";

const LeadsMessages = () => {
  const [messages, setMessages] = useState([]);
  const { user } = useGlobalContext();
  const [loading, setLoading] = useState(true); // Add a loading state

  useEffect(() => {
    // Make an API request to get messages
    const fetchData = async () => {
      try {
        const response = await pipelineService.getMessages(user?.city);
        setMessages(response.data.data); // Assuming the API response contains an array of messages
        setLoading(false); // Set loading to false when data is available
      } catch (error) {
        setLoading(false); // Set loading to false in case of an error
      }
    };

    // fetchData();
  }, [user?.city]);

  const permission = getPrivileges();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const pathname = location.pathname.replace("/", "");

  const [currentPage, setCurrentPage] = useState(1);

  const getUserId = useCallback(
    (user: any) => {
      if (!permission.createAgent) {
        return user;
      }
      return "";
    },
    [permission.createAgent]
  );
  const pageSize: number = 10;

  const filters = {
    user_id: getUserId(user?.id) as string,
    limit: pageSize,
    page: currentPage,
    name: "",
  };

  function renderCell(order: any, column: ProductTableColumn) {
    switch (column.accessor) {
      case "tym":
        const date = new Date(order[column.accessor] * 1000);

        return (
          <span>
            {date.getDate().toString().padStart(2, "0")}-
            {(date.getMonth() + 1).toString().padStart(2, "0")}-
            {date.getFullYear()}
          </span>
        );

      default:
        return <span>{order[column.accessor] ?? "N/A"}</span>;
    }
  }
  const columns: ProductTableColumn[] = [
    { header: "Hospital", accessor: "name" },
    { header: "Sender", accessor: "agent" },
    { header: "Message Content", accessor: "message" },
  ];

  useEffect(() => {
    const pageFromUrl = parseInt(searchParams.get("page") || "1", 10);
    if (pageFromUrl !== currentPage) {
      setCurrentPage(pageFromUrl);
    }
  }, [location.search, searchParams, currentPage]);
  const goToPage = useCallback(
    (page: number) => {
      applyFilters(
        {
          page,
        },
        navigate,
        pathname
      );
      setCurrentPage(page);
    },
    [navigate, pathname]
  );
  return (
    <React.Fragment>
      <Header title="Your Pending Messages" />
      <div className=" flex mx-auto items-center justify-center min-h-dvh h-full">
        <div className="bg-gray-700 absolute inset-0 opacity-50"></div>
        <p className="font-bold text-2xl">Coming Soon</p>
      </div>

      {/* <div className="">
        {loading ? (
          <div className="fixed top-0 bottom-0 left-0 right-0 flex mx-auto items-center justify-center h-dvh z-30">
            <div className="bg-gray-700 absolute inset-0 opacity-50"></div>
            <SyncLoader
              color="#3B82F6"
              loading={loading}
              className="relative"
              size={30}
            />
          </div>
        ) : (
          <>
            <ProductTable
              data={messages}
              columns={columns}
              renderCell={renderCell}
              // totalPages={paginate?.totalPages}
              currentPage={currentPage}
              goToPage={goToPage}
              noDataMessage={"You Currently don't have any pending Message"}
            />
          </>
        )}
      </div> */}
    </React.Fragment>
  );
};

export default LeadsMessages;
