import React, { useCallback, useEffect, useMemo, useState } from "react";

import Header from "../../../components/blocks/header";

import authService from "../../../services/authService";

import AddAppointmentForm from "../../../components/forms/addAppointmentForm";

import ProductTable, { ProductTableColumn } from "./exodus/ProductTable";
import { Link, useLocation, useNavigate } from "react-router-dom";
import applyFilters from "../../../helpers/urlState";
import { Hospital } from "../../../ts-utils/types";
import { PaginatedData } from "../../../ts-utils/interfaces";
import utilities from "../../../utils/utilities";
import * as Dialog from "@radix-ui/react-dialog";
import ReactSelect from "../../../components/common/ReactSelect";
import { Button } from "../../../@/components/ui/button";
import { useGlobalContext } from "../../../contexts/globalContext";
import {
  useAssignHospitalAgent,
  useGetSalesAgent,
} from "../../../hooks/agent/useAgent";
import { Separator } from "../../../@/components/ui/separator";

const SalesAppointments = ({ appointments }: any) => {
  const privileges = authService.getPrivileges();
  const { user } = useGlobalContext();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const pathname = location.pathname.replace("/", "");

  const pageSize: number = 10;

  const { mutateAssignHospitalAgent } = useAssignHospitalAgent();

  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [addHospital, setAddHospital] = useState(false);
  const [hospitalToView, setHospitalToView] = useState<Hospital | null>(null);

  const { mappedAgents } = useGetSalesAgent(hospitalToView?.state as string);

  const handleAddHospital = () => {
    setAddHospital((prev) => !prev);
  };
  const handleHospitalToView = (hospital: Hospital): void => {
    setHospitalToView(hospital);
    setOpen(true);
  };

  const getPaginatedData = (): PaginatedData => {
    let filtered: Hospital[] = appointments;

    let paginated = utilities.paginate(filtered, currentPage, pageSize);

    return { totalCount: filtered.length, paginated };
  };

  const paginatedData: PaginatedData = getPaginatedData();

  useEffect(() => {
    const handleCategories = () => {
      const newCategories = [];

      const allHospitals = { id: "0", name: "All Hospitals" };
      const myHospitals = { id: "1", name: "My Hospitals" };

      if (privileges.allHospitals) newCategories.push(allHospitals);
      if (privileges.myHospitals) newCategories.push(myHospitals);
    };
    handleCategories();
  }, [privileges.allHospitals, privileges.myHospitals]);

  function renderCell(hospital: Hospital, column: ProductTableColumn) {
    switch (column.accessor) {
      case "name":
        return (
          <>
            {hospital?.hospital_id ? (
              <Link
                className="text-[#49209F]  underline font-bold"
                to={`/hospitals/${hospital?.hospital_id}`}
              >
                {hospital?.name ?? "N/A"}
              </Link>
            ) : (
              <> {hospital?.name ?? "N/A"}</>
            )}
          </>
        );
      case "tym":
        const date = hospital[column.accessor]
          ? new Date(hospital[column.accessor] * 1000)
          : null;

        return (
          <span>
            {date
              ? `${date.getDate().toString().padStart(2, "0")}-${(
                  date.getMonth() + 1
                )
                  .toString()
                  .padStart(2, "0")}-${date.getFullYear()}`
              : "N/A"}
          </span>
        );
      case "action":
        return (
          <button
            className="status primary"
            onClick={() => handleHospitalToView(hospital)}
          >
            Assign
          </button>
        );

      default:
        return <span>{hospital[column.accessor] ?? "N/A"}</span>;
    }
  }
  const columns: ProductTableColumn[] = [
    { header: "Hospital Name", accessor: "name" },
    { header: "Contact Number", accessor: "phone" },
    { header: "Contact Email", accessor: "email" },
    { header: "City", accessor: "city" },
    { header: "State", accessor: "state" },
    {
      header: "Appointment Date",
      accessor: "appointmentdate",
      className: "max-w-32 w-32",
    },
    {
      header: "Appointment Time",
      accessor: "appointmenttime",
      className: "max-w-32 w-32",
    },
    {
      header: "Prospecting Mode",
      accessor: "preference",
      className: "max-w-32 w-32",
    },
    { header: "Action", accessor: "action" },
  ];
  useEffect(() => {
    const pageFromUrl = parseInt(searchParams.get("page") || "1", 10);
    if (pageFromUrl !== currentPage) {
      setCurrentPage(pageFromUrl);
    }
  }, [location.search, searchParams, currentPage]);
  const goToPage = useCallback(
    (page: number) => {
      applyFilters(
        {
          page,
        },
        navigate,
        pathname
      );
      setCurrentPage(page);
    },
    [navigate, pathname]
  );
  const [selectedAgentId, setSelectedAgentId] = useState("");
  const handleChange = (value: any) => {
    console.log(value);
    setSelectedAgentId(value);
  };

  // const onAcquisition = () => {
  //   console.log(note);
  //   console.log(hospitalToView.id);
  //   const payload = {
  //     id: hospitalToView.id,
  //     note: note,
  //   };
  // };

  const onAssign = () => {
    // console.log(selectedAgentId);
    // console.log(hospitalToView.id);
    const payload = {
      user_id: selectedAgentId,
      hospital_id: hospitalToView.id,
    };
    mutateAssignHospitalAgent(payload, {
      onSuccess: () => {
        setOpen(false);
      },
    });
  };
  return (
    <React.Fragment>
      <Header title={`New Leads Appointments`} />

      <Dialog.Root
        open={open}
        onOpenChange={() => {
          setOpen(!open);
          setSelectedAgentId("");
        }}
      >
        <Dialog.Portal>
          <Dialog.Overlay className="data-[state=open]:animate-overlayShow fixed inset-0 w-full h-full bg-black opacity-40" />
          <Dialog.Content
            aria-describedby={undefined}
            className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-full max-w-lg mx-auto px-4 text-[#172b4d] font-semibold"
          >
            <div className="bg-white rounded-md shadow-lg px-4 py-6">
              <div className="flex items-center justify-between">
                <p className="text-xl font-bold text-center ">
                  Appointment Information
                </p>
                <Dialog.Close className="p-2 text-gray-400 rounded-md hover:bg-gray-100">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5 mx-auto"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </Dialog.Close>
              </div>
              <div className="max-w-sm mx-auto space-y-3 ">
                <Dialog.Title className="text-2xl font-bold text-gray-800 text-center "></Dialog.Title>

                <div className="max-h-md overflow-y-auto overflow-x-hidden">
                  <p className="flex gap-3 items-center">
                    <i className="fa fa-hospital-o text-red-700"></i>{" "}
                    <span className="font-semibold">Facility Name:</span>{" "}
                    <span className="text-[#4d8c5e]">
                      {hospitalToView?.name}
                    </span>
                  </p>
                  <Separator className="my-2" />
                  <p className="flex gap-3 items-center">
                    <i className="fa fa-envelope-o text-red-700"></i>{" "}
                    <span>Email Address:</span>
                    <span className="text-[#4d8c5e]">
                      {hospitalToView?.email || "N/A"}
                    </span>{" "}
                  </p>
                  <Separator className="my-2" />
                  <p className="flex gap-3 items-center">
                    <i className="fa fa-phone text-red-700"></i>{" "}
                    <span>Phone Number:</span>{" "}
                    <span className="text-[#4d8c5e]">
                      {hospitalToView?.countrycode}-
                      {hospitalToView?.phone || "N/A"}
                    </span>{" "}
                  </p>
                  <Separator className="my-2" />
                  <p className="flex gap-3 items-center">
                    <i className="fa fa-home text-red-700"></i>{" "}
                    <span>Facility Address:</span>{" "}
                    <span className="text-[#4d8c5e]">
                      {hospitalToView?.address || "N/A"}
                    </span>{" "}
                  </p>
                  <Separator className="my-2" />
                  {user?.position !== "sales_lead" && (
                    <p className="flex gap-3 items-center">
                      <i className="fa fa-sitemap text-red-700"></i>{" "}
                      <span>Status:</span>{" "}
                      {/* {getFacilitySize()?.name || "Not Recorded"} */}
                      Pending
                    </p>
                  )}
                  <Separator className="my-2" />
                  <p className="flex gap-3 items-center">
                    <i className="fa fa-user-o text-red-700"></i>{" "}
                    <span>Prospect Mode:</span>{" "}
                    <span className="text-[#4d8c5e]">
                      {hospitalToView?.preference}
                    </span>
                  </p>
                  <Separator className="my-2" />
                  <p className="flex gap-3 items-center text-nowrap">
                    <i className="fa fa-bed text-red-700"></i>{" "}
                    <span>Appointment Date and Time:</span>{" "}
                    <span className="text-[#4d8c5e] text-wrap">
                      {hospitalToView?.appointmentdate} | {hospitalToView?.time}
                    </span>
                  </p>
                  <Separator className="my-2" />

                  {user?.position !== "marketing" && (
                    <span className="complaint-modal-items-assign">
                      <span className="font-semibold inline-block text-gray-400">
                        Assign Sales Rep
                      </span>
                      <ReactSelect
                        placeholder="Select Rep"
                        options={mappedAgents?.map(
                          (hospital: { id: string; username: string }) => ({
                            value: hospital.id,
                            label: hospital.username,
                          })
                        )}
                        onChange={handleChange}
                      />
                      <Button
                        disabled={!selectedAgentId}
                        onClick={onAssign}
                        className=" w-full mt-3 py-3 px-4 font-medium text-sm text-center text-white primary hover:opacity-80   rounded-lg"
                      >
                        Assign
                      </Button>
                    </span>
                  )}
                  {/* {openMessage && (
                    <textarea
                      id="note"
                      name="note"
                      onChange={(e) => setNote(e.target.value)}
                      placeholder="Leave a note"
                      rows={4}
                      cols={50}
                      className="form-field-input mt-2"
                    ></textarea>
                  )}
                  <div className="flex gap-2 mt-2">
                    <Button
                      onClick={() => setOpenMessage(!openMessage)}
                      className="w-full p-3 px-2 font-medium text-sm text-center text-white primary hover:opacity-80   rounded-lg flex items-center gap-2"
                    >
                      <i className="fa fa-envelope"></i>
                      {openMessage ? "Hide note" : "Leave a note"}
                    </Button>
                    <Button
                      onClick={onAcquisition}
                      className="w-full p-3 px-2 font-medium text-sm text-center text-white primary hover:opacity-80   rounded-lg flex items-center gap-2"
                    >
                      <i className="fa fa-paper-plane"></i> Send to acquisition
                    </Button>
                  </div> */}
                </div>
              </div>
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
      {/* {hospitalToView && (
        <AppointmentInfo
          selectedProspect={hospitalToView}
          handleClose={() => handleHospitalToView(null)}
        />
      )} */}
      {privileges.salesAppointments && addHospital && (
        <AddAppointmentForm handleClose={handleAddHospital} />
      )}

      <div className="hospitals-container">
        <div className="hospitals-container-bottom table-responsive mb-5">
          <ProductTable
            data={paginatedData.paginated ?? []}
            columns={columns}
            renderCell={renderCell}
            totalPages={
              Math.ceil(paginatedData.totalCount / pageSize) > 0
                ? Math.ceil(paginatedData.totalCount / pageSize)
                : 1
            }
            currentPage={currentPage}
            goToPage={goToPage}
            noDataMessage={"No appointments to show"}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default SalesAppointments;
