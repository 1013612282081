// KAAForm.tsx
import React from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import LoadingButton from "../../../../components/common/loadingButton";
import { Button } from "../../../../@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../../@/components/ui/form";
import { Input } from "../../../../@/components/ui/input";
import ReactSelect from "../../../../components/common/ReactSelect";

const KAASchema = z.object({
  agent_id: z.string().min(1, "Agent is required"),
  //   revenue: z.number().min(50000, "Must be at least 50,000"),
  revenue: z
    .union([
      z.string({ invalid_type_error: "Revenue must be at least 50,000" }),
      z.number(),
    ]) // Allow both string and number
    .refine(
      (value) => {
        if (typeof value === "string") {
          return /^[0-9]*\.?[0-9]*$/.test(value); // Regex for valid number string
        }
        return value > 0; // Ensure number is positive
      },
      {
        message: "Revenue Amount must be a valid number",
      }
    )
    .transform((value) => (value ? parseFloat(value as string) : null)) // Transform to number or null
    .refine((value) => value !== null && value > 0, {
      message: "Revenue Amount must be a positive number",
    }),
});

type KAAFormData = z.infer<typeof KAASchema>;

interface KAAFormProps {
  agents: Array<{ id: string; name: string }>;
  isPending: boolean;
  onSubmit: (data: KAAFormData) => void;
}

const KAAForm: React.FC<KAAFormProps> = ({ agents, isPending, onSubmit }) => {
  const form = useForm<KAAFormData>({
    resolver: zodResolver(KAASchema),
    defaultValues: {
      agent_id: "",
      revenue: 0,
    },
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <FormField
          control={form.control}
          name="agent_id"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Select Agent</FormLabel>
              <ReactSelect
                placeholder="Select Agent"
                defaultValue={field.value}
                options={agents?.map((agent) => ({
                  value: agent.id,
                  label: agent.name,
                }))}
                onChange={field.onChange}
              />
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="revenue"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Monthly Target Revenue</FormLabel>
              <FormControl>
                <Input
                  placeholder="e.g. 50,000"
                  {...field}
                  value={field.value === null ? "" : field.value} // Empty string for null
                  onChange={(e) => {
                    const inputValue = e.target.value;

                    // If the input is empty, set value to null (so it can be reset)
                    if (inputValue === "") {
                      field.onChange(null);
                    } else {
                      const parsedValue = parseFloat(inputValue);

                      // Only update value if it is a valid number
                      if (!isNaN(parsedValue)) {
                        field.onChange(parsedValue);
                      }
                    }
                  }}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="flex justify-end pt-6">
          {isPending ? (
            <LoadingButton visible message="Please wait" />
          ) : (
            <Button type="submit">Set Targets</Button>
          )}
        </div>
      </form>
    </Form>
  );
};

export default KAAForm;
