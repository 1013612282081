import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";

import Header from "../../../components/blocks/header";
import LoadingButton from "../../../components/common/loadingButton";
import Select from "../../../components/common/select";
import SearchQuery from "../../../components/forms/searchQuery";
// import Pagination from "../../../components/common/pagination";

import utils from "../../../utils/utilities";

import { useGetPipeline, useReadyToPitch } from "../../../hooks/usePipeline";

import { ReloadIcon } from "@radix-ui/react-icons";
import { useGlobalContext } from "../../../contexts/globalContext";
import { Dayjs } from "dayjs";
import CustomPagination from "../../../components/common/pagination/Pagination";

function createCategoryValue(
  key: string,
  date: string | number | Date | Dayjs | null | undefined,
  action: string
) {
  let duration = date ? utils.getDurationFromDate(date) : "N/A";
  let value = date ? date : "Not Available";

  return { key, value, duration, action };
}

const statusCategories = [
  { id: "0", name: "Unassigned" },
  { id: "1", name: "Assigned" },
];

function removePrefix(inputString: string) {
  // Split the input string at the first occurrence of '/'
  const parts = inputString?.split("/");
  // Return the part after the '/'
  return parts.length > 1 ? parts[1] : inputString;
}
const Pipeline = () => {
  const { user } = useGlobalContext();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const contentId = searchParams.get("content");

  const clickedCategory = JSON.parse(
    window.sessionStorage.getItem("pipeline") ?? ""
  );
  const newCategory = clickedCategory;

  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [activeCategory, setActiveCategory] = useState(clickedCategory);
  const [currentPage, setCurrentPage] = useState(1);
  const sortColumn = {
    path: activeCategory?.sortKey,
    order: "desc",
  };
  const searchKey = "name";
  // const [timeFilter, setTimeFilter] = useState("");

  const { pipeline, isPending } = useGetPipeline(user?.city, user?.id);

  // Hospitals to be pitched

  // const filteredAssignedPitch = useMemo(
  //   () =>
  //     pipeline?.pitches
  //       ?.filter(
  //         (pitch: { sub_status: string }) =>
  //           pitch.sub_status !== "To be contacted"
  //       )
  //       .map((pitch: { hospital_id: any }, index: number) => ({
  //         ...pitch,
  //         hospital_id: `${index}/${pitch.hospital_id}`,
  //       })) ?? [],
  //   [pipeline?.pitches]
  // );

  // const filteredPitches = useMemo(
  //   () =>
  //     pipeline?.pitches?.filter(
  //       (pitch: { sub_status: string }) =>
  //         pitch.sub_status === "To be contacted"
  //     ) ?? [],
  //   [pipeline?.pitches]
  // );

  useEffect(() => {
    if (newCategory?.tag === contentId) {
      setActiveCategory(newCategory);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentId]);

  const pageSize = 20;

  const getAllHospitals = () => {
    const allHospitals: any[] = [];

    for (const key in pipeline) {
      const hospitalsInKey = pipeline[key];

      for (const hospital of hospitalsInKey) {
        const match = allHospitals.find((h) => h.id === hospital.id);
        if (!match) allHospitals.push(hospital);
      }
    }

    return utils.mapKeyToNumber(allHospitals, "id");
  };

  const getActiveCategoryHospitals = () => {
    // console.log(activeCategory?.tag)
    let activeCategoryHospitals = pipeline[activeCategory?.tag] ?? [];

    // if (activeCategory?.tag === "pitches") {
    //   activeCategoryHospitals = filteredAssignedPitch;
    // }
    // if (activeCategory?.tag === "assign") {
    //   activeCategoryHospitals = [
    //     ...filteredPitches,
    //     ...activeCategoryHospitals,
    //   ];
    // }

    return activeCategoryHospitals ?? [];
    // return uniquePipeline(activeCategoryHospitals) ?? [];
  };

  const getMatchingHospitals = (sample: any[]) => {
    let searchCriteria = searchKey ? searchKey : "name";

    return sample.filter((h: { [x: string]: string }) => {
      let searchValue = h[searchCriteria] ?? "";

      return searchValue.toLowerCase().startsWith(search.toLowerCase());
    });
  };

  const getPaginatedData = () => {
    let allHospitals = getAllHospitals();

    if (activeCategory?.tag) allHospitals = getActiveCategoryHospitals();
    if (search) allHospitals = getMatchingHospitals(allHospitals);

    if (status)
      allHospitals = allHospitals.filter((h) => h.assigned === status);

    const sorted = _.orderBy(
      allHospitals,
      [sortColumn.path],
      [sortColumn.order === "desc" ? "desc" : "asc"]
    );
    const paginated = utils.paginate(sorted, currentPage, pageSize);

    return { totalCount: allHospitals.length, paginated };
  };

  const getValueByCategory = (hospital: {
    created_at: any;
    date: any;
    onboard_date: any;
    actualonboard: any;
    active_date: any;
    inactive_date: any;
    hibernate_date: any;
  }) => {
    let newHospitalValue = createCategoryValue(
      "Created on",
      hospital.created_at,
      "creation"
    );

    switch (activeCategory?.tag) {
      case "new_hospitals":
        return newHospitalValue;
      case "prospected":
        return createCategoryValue(
          "Prospected on",
          hospital.date,
          "prospected"
        );
      case "pitches":
        return createCategoryValue("Pitched on", hospital.date, "pitch");
      case "onboard":
        return createCategoryValue("Due on", hospital.onboard_date, "due date");
      case "exodus":
        return createCategoryValue(
          "Exodus began on",
          hospital.actualonboard,
          "exodus"
        );
      case "close":
        return createCategoryValue(
          "Created on",
          hospital.created_at,
          "Created"
        );
      case "active":
        return createCategoryValue(
          "Active since",
          hospital.active_date,
          "activation"
        );
      case "inactive":
        return createCategoryValue(
          "Inactive since",
          hospital.inactive_date,
          "inactivity"
        );
      case "hibernated":
        return createCategoryValue(
          "Hibernated since",
          hospital.hibernate_date,
          "hibernated"
        );
      default:
        return newHospitalValue;
    }
  };

  const { totalCount, paginated } = getPaginatedData();

  const { mutate: readyToPitch, isPending: isPitching } = useReadyToPitch();

  const [clickedRowId, setClickedRowId] = useState<number | string | null>(
    null
  );

  const handleReadyToPitch = useCallback(
    (hospital_id: string | number | null) => {
      const payload = {
        hospital_id,
        user_id: user?.id as string,
      };
      setClickedRowId(hospital_id);
      readyToPitch(payload);
    },
    [readyToPitch, user?.id]
  );
  const goToPage = (page: number) => {
    setCurrentPage(Math.min(Math.max(page, 1), totalCount));
    // applyFilters(
    //   {
    //     page: Math.min(Math.max(page, 1), totalCount),
    //   },
    //   navigate,
    //   `pipeline?content=${activeCategory?.tag}`
    // );
  };

  useEffect(() => {
    if (paginated.length === 0) {
      setCurrentPage(1);
    }
  }, [paginated.length, setCurrentPage]);

  return (
    <React.Fragment>
      <Header title={`Pipeline - ${activeCategory?.title} (${totalCount})`} />

      <div className="pipeline-container">
        <div className="pipeline-container-top">
          <SearchQuery
            name="search"
            placeholder="Search"
            value={search}
            onChange={(e: {
              currentTarget: { value: React.SetStateAction<string> };
            }) => setSearch(e.currentTarget.value)}
          />

          <div className="right-options">
            <Select
              name="status"
              placeholder="Assigned Status"
              value={status}
              options={statusCategories}
              onChange={(e: {
                currentTarget: { value: React.SetStateAction<string> };
              }) => setStatus(e.currentTarget.value)}
            />
          </div>
        </div>
        <div className="pipeline-container-bottom">
          <LoadingButton visible={isPending} message="Fetching Pipelines" />

          {!isPending && (
            <div className="pipeline-container-bottom-inner">
              {paginated.map((hospital, index) => {
                const categoryValue = getValueByCategory(hospital);

                return (
                  <div
                    className="single-pipeline-item"
                    key={hospital.hospital_id ?? index + 1}
                  >
                    <h4
                      className="single-pipeline-item-title text-truncate"
                      style={{ color: activeCategory?.color }}
                    >
                      <Link
                        to={
                          "/hospitals/" +
                          removePrefix(hospital.hospital_id ?? hospital.id)
                        }
                      >
                        {hospital.name ?? hospital.hospital_name}
                      </Link>
                    </h4>
                    <p className="single-pipeline-item-subtitle">
                      {categoryValue.key}:{" "}
                      <span>{categoryValue?.value as string}</span>
                      {/* )} */}
                    </p>
                    <p className="single-pipeline-item-subtitle">
                      Days since {categoryValue.action}:
                      <span
                        className={
                          Number(categoryValue?.duration) > 0
                            ? "error"
                            : "neutral"
                        }
                      >
                        {" "}
                        {categoryValue.duration}
                      </span>
                    </p>

                    <div className="single-pipeline-item-subtitle w-full">
                      {user?.position === "sales_rep" &&
                      contentId === "onboard" ? (
                        <button
                          className="app-btn text-white status primary"
                          onClick={() => {
                            window.sessionStorage.setItem(
                              "checklist",
                              JSON.stringify(hospital)
                            );
                            navigate(`/checklist/${hospital.hospital_id}`);
                          }}
                        >
                          Checklist
                        </button>
                      ) : user?.position === "sales_rep" &&
                        contentId === "close" ? (
                        <Link
                          className="app-btn text-white px-4 rounded-full"
                          to={"/request-info?id=" + hospital.id}
                        >
                          View
                        </Link>
                      ) : (user?.position === "sales_rep" ||
                          user?.position === "sales_lead") &&
                        contentId === "assign" ? (
                        <>
                          {hospital.id === clickedRowId && isPitching ? (
                            <p className="flex items-center">
                              <ReloadIcon className="animate-spin mr-2 h-4 w-4" />
                              <span>Processing...</span>
                            </p>
                          ) : (
                            <span
                              className="app-btn text-white px-4 rounded-full"
                              onClick={() => handleReadyToPitch(hospital.id)}
                            >
                              Ready to Pitch
                            </span>
                          )}
                        </>
                      ) : (user?.position === "sales_rep" ||
                          user?.position === "sales_lead") &&
                        contentId === "pitches" ? (
                        <Link
                          className="app-btn text-white px-4 rounded-full"
                          to={
                            "/info?id=" +
                            removePrefix(hospital?.hospital_id ?? "")
                          }
                        >
                          Pitch
                        </Link>
                      ) : (user?.position === "sales_rep" ||
                          user?.position === "sales_lead") &&
                        contentId === "exodus" ? (
                        <Link
                          className="app-btn text-white px-4 rounded-full"
                          to={`/exodus/log-a-contact/contact?id=${hospital.id}`}
                          state={
                            hospital.hospital_id
                              ? { id: hospital.hospital_id }
                              : { id: hospital.id }
                          }
                        >
                          Contact
                        </Link>
                      ) : user?.position === "sales_rep" &&
                        contentId === "active" ? (
                        <Link
                          className="app-btn text-white px-4 rounded-full"
                          to={`/active-schedules?id=${hospital.id}`}
                        >
                          Process
                        </Link>
                      ) : null}
                    </div>
                  </div>
                );
              })}

              {paginated.length === 0 && (
                <div className="no-items">
                  <p>No facilities to show</p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <div className="sb-pagination-container mb-5">
        <CustomPagination
          goToPage={goToPage}
          currentPage={currentPage}
          totalPages={
            Math.ceil(totalCount / pageSize) > 0
              ? Math.ceil(totalCount / pageSize)
              : 1
          }
        />
      </div>
    </React.Fragment>
  );
};

export default Pipeline;
