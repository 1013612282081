import {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import Header from "../../../../components/blocks/header";
import {
  useGetLogisticsExpenses,
  useGetLogisticEstimatedCost,
  useSettleExpense,
  useGetAllDisbursement,
  useGetTotalAmountDisbursed,
} from "../../../../hooks/logistics/useLogistics";
import { useGlobalContext } from "../../../../contexts/globalContext";
import { useLocation, useNavigate } from "react-router-dom";
import ProductTable, { ProductTableColumn } from "../exodus/ProductTable";
import applyFilters from "../../../../helpers/urlState";
import { SyncLoader } from "react-spinners";
import * as Dialog from "@radix-ui/react-dialog";
import { getPrivileges } from "../../../../services/authService";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../../@/components/ui/dropdown-menu";
import { MoreVertical, Eye } from "lucide-react";
import { Button } from "../../../../@/components/ui/button";
import { Skeleton } from "../../../../@/components/ui/skeleton";
import SearchQuery from "../../../../components/forms/searchQuery";
import { Card, CardContent } from "../../../../@/components/ui/card";
import AnimatedCounter from "../../../../components/AnimatedCounter";

import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import NewDisbursement from "./NewDisbursement";
import { useGetAllAgent } from "../../../../hooks/agent/useAgent";

const formatDate = (date: Date) => {
  if (date) {
    console.log(date.toISOString().slice(0, 10));
    return date.toISOString().slice(0, 10);
  }
};

export default function LogisticTracker() {
  const permission = getPrivileges();
  const { user, country } = useGlobalContext();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const pathname = location.pathname.replace("/", "");

  const [currentPage, setCurrentPage] = useState(1);
  const [estimated, setEstimated] = useState<{
    user_id: string;
    id: string;
  } | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const getInitialDateRange = () => {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    // Get tomorrow's date
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    return [firstDayOfMonth, tomorrow];
  };
  const [dateRange, setDateRange] = useState(getInitialDateRange);

  const getUserId = useCallback(
    (user: any) => {
      if (!permission.createAgent) {
        return user;
      }
      return "";
    },
    [permission.createAgent]
  );
  const pageSize: number = 10;

  const filters = {
    user_id: getUserId(user?.id) as string,
    limit: pageSize,
    page: currentPage,
    name: "",
    proposed_date: `${formatDate(dateRange[0])},${formatDate(dateRange[1])}`,
  };
  const { expenses, isPending, paginate } = useGetLogisticsExpenses(filters);
  // const { cost, isPending: isCostPending } = useGetLogisticEstimatedCost(
  //   estimated?.user_id as string
  // );
  // const { mutateSettleExpense, isPending: isApproving } = useSettleExpense();
  const [estimatedCost, setEstimatedCost] = useState(false);

  const handleViewEstimatedCost = (
    user: SetStateAction<{ user_id: string; id: string } | null>
  ) => {
    setEstimated(user);
  };

  function renderCell(user: any, column: ProductTableColumn) {
    switch (column.accessor) {
      case "createdat":
        const date = new Date(user[column.accessor]);
        return <span>{date.toLocaleDateString()}</span>;
      case "date_incured":
        const dateIncurred = new Date(user[column.accessor]);
        return <span>{dateIncurred.toLocaleDateString()}</span>;
      case "action":
        return (
          <div>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="h-8 w-8 p-0">
                  <span className="sr-only">Open menu</span>
                  <MoreVertical className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem
                  className="cursor-pointer"
                  onClick={() => {
                    handleViewEstimatedCost(user);
                    setEstimatedCost(true);
                  }}
                >
                  <Eye className="mr-2 h-4 w-4" /> View
                </DropdownMenuItem>
                {/* {permission.createAgent && (
                  <DropdownMenuItem
                    className="cursor-pointer"
                    onClick={() => {
                      handleViewEstimatedCost(user);
                      setEstimatedCost(true);
                    }}
                  >
                    <Pencil className="mr-2 h-4 w-4" /> Approve Estimated Cost
                  </DropdownMenuItem>
                )} */}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        );
      default:
        return <span>{user[column.accessor] ?? "N/A"}</span>;
    }
  }
  const columns: ProductTableColumn[] = [
    { header: "Name", accessor: "username" },
    { header: "Start Address", accessor: "start" },
    { header: "Destination Address", accessor: "destination" },
    { header: "Distance(km)", accessor: "estimated_distance" },
    { header: "Cost", accessor: "estimated_cost" },
    { header: "Date", accessor: "createdat" },
    // { header: "Date Incurred", accessor: "date_incured" },
    { header: "City", accessor: "city" },
    { header: "Country", accessor: "country" },
    // { header: "Email", accessor: "email" },
    // { header: "Feedback", accessor: "note" },
    { header: "Action", accessor: "action" },
  ];
  const adminFilters = {
    user_id: getUserId(user?.id) as string,
    limit: pageSize,
    page: currentPage,
    name: "",
    proposed_date: `${formatDate(dateRange[0])},${formatDate(dateRange[1])}`,
  };
  const { disbursement, isPending: isDisbursement } =
    useGetAllDisbursement(adminFilters);
  const { totalAmount } = useGetTotalAmountDisbursed(adminFilters);

  const adminColumns: ProductTableColumn[] = [
    { header: "Name", accessor: "username" },
    { header: "Amount Disbursed", accessor: "amount" },
    // { header: "Facilities Visited", accessor: "destination" },
    { header: "Amount Spent", accessor: "amount_spent" },
    { header: "Available Amount", accessor: "balance_left" },
    { header: "City", accessor: "city" },
    { header: "Country", accessor: "country" },
    { header: "Action", accessor: "action" },
  ];

  useEffect(() => {
    const pageFromUrl = parseInt(searchParams.get("page") || "1", 10);
    if (pageFromUrl !== currentPage) {
      setCurrentPage(pageFromUrl);
    }
  }, [location.search, searchParams, currentPage]);

  const goToPage = useCallback(
    (page: number) => {
      applyFilters(
        {
          page,
        },
        navigate,
        pathname
      );
      setCurrentPage(page);
    },
    [navigate, pathname]
  );

  // const onApprove = () => {
  //   const payload = {
  //     id: estimated?.id,
  //     approverid: user?.id,
  //   };
  //   mutateSettleExpense(payload, {
  //     onSuccess: (data: { data: { success: boolean } }) => {
  //       console.log(data);
  //       if (data?.data?.success) {
  //         setEstimatedCost(false);
  //       }
  //     },
  //   });
  // };
  const [createDisbursement, setCreateDisbursement] = useState(false);
  const { agent } = useGetAllAgent(permission.createAgent as boolean);

  // filter agent with position sales_rep or sales_lead
  const filterAgents = useMemo(
    () =>
      agent
        ?.filter((agent: { position: string }) =>
          ["sales_rep", "sales_lead"].includes(agent.position)
        )
        .map((agent: { id: string; username: string }) => {
          return {
            id: agent.id,
            name: agent.username,
          };
        }) || [],
    [agent]
  );
  return (
    <>
      <div className="w-full">
        <Header title={"Logistic Tracker"} />
      </div>

      <>
        {!permission.createAgent && isPending ? (
          <div className="fixed top-0 bottom-0 left-0 right-0 flex mx-auto items-center justify-center h-dvh z-30">
            <div className="bg-gray-700 absolute inset-0 opacity-50"></div>
            <SyncLoader
              color="#3B82F6"
              loading={isPending}
              className="relative"
              size={30}
            />
          </div>
        ) : (
          <>
            <div className="mx-auto px-4 md:px-8 mb-5 space-y-5">
              <div className="flex flex-col md:flex-row gap-2 justify-between items-center">
                <SearchQuery
                  name="search"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={(e: {
                    target: { value: React.SetStateAction<string> };
                  }) => setSearchTerm(e.target.value)}
                />
                <div className="flex flex-col-reverse lg:flex-row items-center justify-center gap-2">
                  {!permission.createAgent && (
                    <div className="flex items-center gap-2">
                      <Card className="flex-1 md:px-2 bg-green-100">
                        <CardContent className="p-2 space-y-1">
                          <h1 className="font-semibold text-nowrap text-green-600">
                            Disbursed
                          </h1>
                          <div className="text-green-800 font-semibold">
                            <AnimatedCounter
                              amount={totalAmount?.totalamount ?? 0}
                              prefix={country?.currency as string}
                              decimal={2}
                            />
                          </div>
                        </CardContent>
                      </Card>
                      <Card className="flex-1 md:px-2 bg-yellow-100">
                        <CardContent className="p-2 space-y-1">
                          <h1 className="font-semibold text-nowrap text-yellow-600">
                            Spent
                          </h1>
                          <div className="text-yellow-800 font-semibold">
                            <AnimatedCounter
                              amount={totalAmount?.amount_spent ?? 0}
                              prefix={country?.currency as string}
                              decimal={2}
                            />
                          </div>
                        </CardContent>
                      </Card>
                      <Card className="flex-1 md:px-2 bg-blue-100">
                        <CardContent className="p-2 space-y-1">
                          <h1 className="font-semibold text-nowrap text-blue-600">
                            Balance
                          </h1>
                          <div className="text-blue-800 font-semibold">
                            <AnimatedCounter
                              amount={totalAmount?.balance_left ?? 0}
                              prefix={country?.currency as string}
                              decimal={2}
                            />
                          </div>
                        </CardContent>
                      </Card>
                    </div>
                  )}

                  <div className="w-full max-w-[16rem]">
                    <p>Filter Date Range:</p>
                    <Flatpickr
                      value={dateRange}
                      options={{
                        mode: "range", // Enables date range selection
                        dateFormat: "m/d/Y", // Customize the format as per your requirement
                      }}
                      onChange={(selectedDates: SetStateAction<Date[]>) => {
                        setDateRange(selectedDates);
                      }}
                      className="w-full border p-2 rounded-md"
                    />
                  </div>
                  {permission.createAgent && (
                    <div className="self-end">
                      <Button onClick={() => setCreateDisbursement(true)}>
                        Create Disbursement
                      </Button>
                    </div>
                  )}
                </div>
              </div>
              {permission.createAgent ? (
                <>
                  <NewDisbursement
                    agents={filterAgents}
                    createDisbursement={createDisbursement}
                    setCreateDisbursement={setCreateDisbursement}
                  />
                  <ProductTable
                    data={disbursement}
                    columns={adminColumns}
                    renderCell={renderCell}
                    totalPages={paginate?.totalPages}
                    currentPage={currentPage}
                    goToPage={goToPage}
                    noDataMessage={"You Currently Don't Have Any Expenses"}
                    searchTerm={searchTerm}
                  />
                </>
              ) : (
                <>
                  <ProductTable
                    data={expenses}
                    columns={columns}
                    renderCell={renderCell}
                    totalPages={paginate?.totalPages}
                    currentPage={currentPage}
                    goToPage={goToPage}
                    noDataMessage={"You Currently Don't Have Any Expenses"}
                    searchTerm={searchTerm}
                  />
                </>
              )}
            </div>
          </>
        )}
      </>
      {/* Approval modal */}
      <Dialog.Root open={estimatedCost} onOpenChange={setEstimatedCost}>
        <Dialog.Portal>
          <Dialog.Overlay className="data-[state=open]:animate-overlayShow fixed inset-0 w-full h-full bg-black opacity-40" />
          <Dialog.Content
            aria-describedby={undefined}
            className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-full max-w-lg mx-auto px-4"
          >
            <div className="bg-white rounded-md shadow-lg px-4 py-6">
              <div className="flex items-center justify-between">
                <h1 className="font-semibold">Agent Estimated Cost</h1>
                <div className="flex items-center justify-end">
                  <Dialog.Close
                    className="p-2 text-gray-400 rounded-md hover:bg-gray-100"
                    onClick={() => {}}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-5 h-5 mx-auto"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </Dialog.Close>
                </div>
              </div>

              {/* {isCostPending ? (
                <div className="max-w-sm mx-auto space-y-3">
                  <Dialog.Title className="text-lg font-medium text-gray-800 "></Dialog.Title>
                  <div className="flex items-center justify-between">
                    <Skeleton className="h-4 w-[50px]" />
                    <Skeleton className="h-4 w-[50px]" />
                  </div>
                  <div className="flex items-center justify-between">
                    <Skeleton className="h-4 w-[50px]" />
                    <Skeleton className="h-4 w-[50px]" />
                  </div>
                  <div className="flex items-center justify-center gap-3">
                    <Skeleton className="h-10 w-20" />
                    <Skeleton className="h-10 w-20" />
                  </div>
                </div>
              ) : (

                
              )} */}
              <div className="max-w-sm mx-auto space-y-3">
                <Dialog.Title className="text-lg font-medium text-gray-800 "></Dialog.Title>

                {permission.createAgent ? (
                  <>
                    {[
                      "username",
                      "email",
                      "amount",
                      "amount_spent",
                      "balance_left",
                      "note",
                    ].map((key) => (
                      <div
                        key={key}
                        className="flex justify-between items-center border border-[#474747] rounded-md p-1"
                      >
                        <p className="text-sm font-semibold capitalize w-1/3 text-atomGray text-nowrap p-1">
                          {key.replace("_", " ")}:
                        </p>
                        <p className="border border-dashed rounded-md text-sm w-2/3 text-atomGray font-light text-nowrap text-start bg-gray-700/25 p-1">
                          {estimated
                            ? String(estimated[key as keyof typeof estimated])
                            : "N/A"}
                        </p>
                      </div>
                    ))}
                    {/* <div className="flex justify-between items-center border border-[#474747] rounded-md p-1">
                        <p className="text-sm font-semibold capitalize w-1/3 text-atomGray text-nowrap p-1">
                          Total Cost:
                        </p>
                        <p className="border border-dashed rounded-md text-sm w-2/3 text-atomGray font-light text-nowrap text-start bg-gray-700/25 p-1">
                          {country?.currency} {cost?.cost}
                        </p>
                      </div> */}
                    {/* <div className="flex justify-between items-center border border-[#474747] rounded-md p-1">
                        <p className="text-sm font-semibold capitalize w-1/3 text-atomGray text-nowrap p-1">
                          Count:
                        </p>
                        <p className="border border-dashed rounded-md text-sm w-2/3 text-atomGray font-light text-nowrap text-start bg-gray-700/25 p-1">
                          {cost?.count}
                        </p>
                      </div> */}
                  </>
                ) : (
                  <>
                    {["username", "email", "start", "destination", "note"].map(
                      (key) => (
                        <div
                          key={key}
                          className="flex justify-between items-center border border-[#474747] rounded-md p-1"
                        >
                          <p className="text-sm font-semibold capitalize w-1/3 text-atomGray text-nowrap p-1">
                            {key}:
                          </p>
                          <p className="border border-dashed rounded-md text-sm w-2/3 text-atomGray font-light text-nowrap text-start bg-gray-700/25 p-1">
                            {estimated
                              ? String(estimated[key as keyof typeof estimated])
                              : "N/A"}
                          </p>
                        </div>
                      )
                    )}
                  </>
                )}

                {/* {permission.createAgent && (
                    <div className="flex items-center justify-center gap-3">
                      <Button
                        onClick={() => setEstimatedCost(false)}
                        variant={"destructive"}
                      >
                        Cancel
                      </Button>
                      <Button onClick={onApprove}>
                        {isApproving ? "Approving..." : "Approve"}
                      </Button>
                    </div>
                  )} */}
              </div>
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
}
