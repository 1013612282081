import React, { useCallback, useEffect, useMemo, useState } from "react";
// import { SelectOption } from "../../../ts-utils/interfaces";

import AddComplaintForm from "../../../components/forms/addComplaintForm";
// import ComplaintsTable from "../../../components/tables/complaintsTable";
import Button from "../../../components/common/button";
import Header from "../../../components/blocks/header";
import SearchQuery from "../../../components/forms/searchQuery";

// import { getComplaintCategories } from "../../../services/complaintsService";
import { getPrivileges } from "../../../services/authService";
// import { error as toastError } from "../../../services/toastService";
import { useGetComplaint } from "../../../hooks/useDashboard";
import { useGlobalContext } from "../../../contexts/globalContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import applyFilters from "../../../helpers/urlState";
import ProductTable, { ProductTableColumn } from "./exodus/ProductTable";
import { Hospital } from "../../../ts-utils/types";
import { SyncLoader } from "react-spinners";
import ReactSelect from "../../../components/common/ReactSelect";

const filterStatus = [
  {
    value: "",
    label: "All",
  },
  {
    value: "open",
    label: "Open",
  },
  {
    value: "good",
    label: "Good",
  },
  {
    value: "pending",
    label: "Pending",
  },
  {
    value: "close",
    label: "Close",
  },
];
const ComplaintsPage = (): JSX.Element => {
  const { user } = useGlobalContext();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const pathname = location.pathname.replace("/", "");

  const pageSize: number = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [status, setStatus] = useState<string>(
    searchParams.get("status") || ""
  );
  const [search, setSearch] = useState(searchParams.get("search") || "");

  const filters = {
    user_id: user?.id as string,
    limit: pageSize,
    page: currentPage,
    name: search,
    status: status,
  };
  const { complaints, isPending, paginate } = useGetComplaint(filters);
  const [addComplaint, setAddComplaint] = useState<boolean>(false);

  const privileges = getPrivileges();

  const handleAddComplaint = (): void => {
    setAddComplaint((prev) => !prev);
  };

  function renderCell(hospital: Hospital, column: ProductTableColumn) {
    switch (column.accessor) {
      case "name":
        return (
          <>
            {hospital?.hospital_id ? (
              <Link
                className="text-[#49209F]  underline font-bold"
                to={`/hospitals/${hospital?.hospital_id}`}
              >
                {hospital?.name ?? "N/A"}
              </Link>
            ) : (
              <> {hospital?.name ?? "N/A"}</>
            )}
          </>
        );
      case "tym":
        const date = hospital[column.accessor]
          ? new Date(hospital[column.accessor] * 1000)
          : null;

        return (
          <span>
            {date
              ? `${date.getDate().toString().padStart(2, "0")}-${(
                  date.getMonth() + 1
                )
                  .toString()
                  .padStart(2, "0")}-${date.getFullYear()}`
              : "N/A"}
          </span>
        );
      case "status":
        return (
          <span
            style={{
              backgroundColor:
                hospital[column.accessor] === "open"
                  ? "#4CAF50"
                  : hospital[column.accessor] === "good"
                  ? "#3B82F6"
                  : hospital[column.accessor] === "close"
                  ? "#f44336"
                  : hospital[column.accessor] === "pending"
                  ? "#FFD700"
                  : "#f44336",
              color: "white",
              padding: "5px 10px",
              borderRadius: "20px",
              display: "inline-block",
              textTransform: "uppercase",
            }}
          >
            {hospital[column.accessor] ?? "N/A"}
          </span>
        );

      default:
        return <span>{hospital[column.accessor] ?? "N/A"}</span>;
    }
  }
  const columns: ProductTableColumn[] = [
    { header: "Hospital", accessor: "name" },
    { header: "Assigned", accessor: "assign" },
    { header: "Product", accessor: "title" },
    { header: "Status", accessor: "status" },
    { header: "Date", accessor: "tym", className: "text-nowrap" },
    { header: "Subject", accessor: "description", className: "max-w-32 w-32" },
    { header: "Reason", accessor: "reason", className: "max-w-32 w-32" },
    // { header: "Action", accessor: "action" },
  ];
  useEffect(() => {
    const pageFromUrl = parseInt(searchParams.get("page") || "1", 10);
    if (pageFromUrl !== currentPage) {
      setCurrentPage(pageFromUrl);
    }
  }, [location.search, searchParams, currentPage]);
  const goToPage = useCallback(
    (page: number) => {
      applyFilters(
        {
          page,
          search,
        },
        navigate,
        pathname
      );
      setCurrentPage(page);
    },
    [navigate, pathname, search]
  );

  const handleStatusChange = (status: React.SetStateAction<string>) => {
    setStatus(status);
    navigate(`?status=${status}&page=${currentPage}`, {
      replace: true,
    });
  };
  return (
    <React.Fragment>
      <Header title="Complaints" />

      {privileges.addComplaint && addComplaint && (
        <AddComplaintForm handleClose={handleAddComplaint} />
      )}

      {isPending ? (
        <div className="fixed top-0 bottom-0 left-0 right-0 flex mx-auto items-center justify-center h-dvh z-30">
          <div className="bg-gray-700 absolute inset-0 opacity-50"></div>
          <SyncLoader
            color="#3B82F6"
            loading={isPending}
            className="relative"
            size={30}
          />
        </div>
      ) : (
        <div className="hospitals-container">
          <div className="hospitals-container-top">
            <SearchQuery
              name="search"
              placeholder="Search"
              value={search}
              onChange={(e: any) => setSearch(e.currentTarget.value)}
            />

            <div className="right-options">
              {/* <Select
              name="filter"
              placeholder="Search by"
              value={status}
              options={statusCategories}
              onChange={(e: any) => setStatus(e.currentTarget.value)}
            />*/}

              {/* <Select
                name="status"
                placeholder="All Categories"
                value={status}
                options={filterStatus}
                onChange={(e: any) => setStatus(e.currentTarget.value)}
              /> */}

              <ReactSelect
                placeholder="Filter by Status"
                // defaultValue={status}
                options={filterStatus?.map(
                  (status: { value: string; label: string }) => ({
                    value: status.value,
                    label: status.label,
                  })
                )}
                onChange={handleStatusChange}
              />
              {privileges.addComplaint && (
                <Button
                  icon="plus"
                  label="Add New Complaint"
                  onClick={handleAddComplaint}
                />
              )}
            </div>
          </div>

          <div className="hospitals-container-bottom table-responsive mb-5">
            <>
              <ProductTable
                data={complaints ?? []}
                columns={columns}
                renderCell={renderCell}
                totalPages={paginate?.totalPages}
                currentPage={currentPage}
                goToPage={goToPage}
                noDataMessage={"You Currently don't have any pending Message"}
              />
            </>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

// const statusCategories: SelectOption[] = [
//   { id: "name", name: "Name" },
//   { id: "contact_name", name: "Contact Name" },
//   { id: "contact_phone_number", name: "Contact Phone Number" },
// ];

export default ComplaintsPage;
