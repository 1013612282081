import httpService from "./httpService";

export const getPipelineData = (
  id: string | undefined,
  city?: string | undefined
) => {
  // return httpService.get(`/admin/pipeline/data/${city}/${id}`);
  return httpService.get(`/admin/pipeline/data/${id}`);
};

export const nerveUsageMessages = (filters: {
  page?: number | string;
  limit?: number;
  name?: string;
  user_id?: string;
}) => {
  const queryParams = new URLSearchParams();

  if (
    filters.page !== null &&
    filters.page !== undefined &&
    filters.page !== 0 &&
    filters.page !== ""
  ) {
    queryParams.append("page", filters.page.toString());
  }
  if (
    filters.limit !== null &&
    filters.limit !== undefined &&
    filters.limit !== 0
  ) {
    queryParams.append("limit", filters.limit.toString());
  }

  if (
    filters.name !== null &&
    filters.name !== undefined &&
    filters.name !== ""
  ) {
    queryParams.append("name", filters.name.toLowerCase());
  }
  if (
    filters.user_id !== null &&
    filters.user_id !== undefined &&
    filters.user_id !== ""
  ) {
    queryParams.append("user_id", filters.user_id);
  }

  return httpService.get(
    `/manage/hospital/why/nerve/${filters.user_id}?${queryParams.toString()}`
  );
  // return httpService.get(
  //   `/manage/hospital/why/nerve?${queryParams.toString()}`
  // );
};

export const readyToPitch = (data: {
  hospital_id: string | number | null;
  user_id: string;
}) => {
  return httpService.post(`/manage/ready/pitch`, data);
};

const pipelineService = {
  getPipelineData,
  nerveUsageMessages,
  readyToPitch,
};

export default pipelineService;
