import React, { useCallback, useEffect, useMemo, useState } from "react";
import Header from "../../../../components/blocks/header";

import { useGlobalContext } from "../../../../contexts/globalContext";
import { useNerveMessage } from "../../../../hooks/usePipeline";
import ProductTable, { ProductTableColumn } from "../exodus/ProductTable";
import { Hospital } from "../../../../ts-utils/types";
import { Link, useLocation, useNavigate } from "react-router-dom";
import applyFilters from "../../../../helpers/urlState";
import SearchQuery from "../../../../components/forms/searchQuery";
import { SyncLoader } from "react-spinners";

const NerveUsage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const pathname = location.pathname.replace("/", "");

  const [search, setSearch] = useState(searchParams.get("search") || "");

  const handleSearch = (e: any) => {
    setSearch(e);
    applyFilters(
      {
        page: 1,
        search: e,
      },
      navigate,
      pathname
    );
  };

  const { user } = useGlobalContext();

  const pageSize: number = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const filters = {
    user_id: user?.id as string,
    limit: pageSize,
    page: currentPage,
    name: search,
  };
  const { messages, isPending, paginate } = useNerveMessage(filters);

  function renderCell(hospital: Hospital, column: ProductTableColumn) {
    switch (column.accessor) {
      case "name":
        return (
          <>
            {hospital?.hospital_id ? (
              <Link
                className="text-[#49209F]  underline font-bold"
                to={`/hospitals/${hospital?.hospital_id}`}
              >
                {hospital?.name ?? "N/A"}
              </Link>
            ) : (
              <> {hospital?.name ?? "N/A"}</>
            )}
          </>
        );
      case "created":
        const date = new Date(hospital[column.accessor] * 1000);

        return (
          <span>
            {date.getDate().toString().padStart(2, "0")}-
            {(date.getMonth() + 1).toString().padStart(2, "0")}-
            {date.getFullYear()}
          </span>
        );

      default:
        return <span>{hospital[column.accessor]}</span>;
    }
  }
  const columns: ProductTableColumn[] = [
    { header: "Hospital", accessor: "name" },
    { header: "Date", accessor: "created" },
    { header: "Message Content", accessor: "message" },
    // { header: "Action", accessor: "action" },
  ];
  useEffect(() => {
    const pageFromUrl = parseInt(searchParams.get("page") || "1", 10);
    if (pageFromUrl !== currentPage) {
      setCurrentPage(pageFromUrl);
    }
  }, [location.search, searchParams, currentPage]);
  const goToPage = useCallback(
    (page: number) => {
      applyFilters(
        {
          page,
          search,
        },
        navigate,
        pathname
      );
      setCurrentPage(page);
    },
    [navigate, pathname, search]
  );

  return (
    <React.Fragment>
      <Header title="Your Nerve Usage Messages" />
      <div className="">
        {isPending ? (
          <div className="fixed top-0 bottom-0 left-0 right-0 flex mx-auto items-center justify-center h-dvh z-30">
            <div className="bg-gray-700 absolute inset-0 opacity-50"></div>
            <SyncLoader
              color="#3B82F6"
              loading={isPending}
              className="relative"
              size={30}
            />
          </div>
        ) : (
          <>
            <div className="hospitals-container-top">
              <SearchQuery
                name="search"
                placeholder="Search"
                value={search}
                onChange={(value: any) => handleSearch(value.target.value)}
              />
            </div>
            <div className="mx-auto px-4 md:px-8 mb-5">
              <>
                <ProductTable
                  data={messages}
                  columns={columns}
                  renderCell={renderCell}
                  totalPages={paginate?.totalPages}
                  currentPage={currentPage}
                  goToPage={goToPage}
                  noDataMessage={"You Currently don't have any pending Message"}
                />
              </>
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  );
};

export default NerveUsage;
