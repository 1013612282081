// CAAForm.tsx
import React from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import LoadingButton from "../../../../components/common/loadingButton";
import { Button } from "../../../../@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../../@/components/ui/form";
import { Input } from "../../../../@/components/ui/input";
import ReactSelect from "../../../../components/common/ReactSelect";

const CAASchema = z.object({
  agent_id: z.string().min(1, "Agent is required"),
  actual_hospital: z.number().min(1, "Must be at least 1"),
  active_hospitals: z.number().min(1, "Must be at least 1"),
});

type CAAFormData = z.infer<typeof CAASchema>;

interface CAAFormProps {
  agents: Array<{ id: string; name: string }>;
  isPending: boolean;
  onSubmit: (data: CAAFormData) => void;
}

const CAAForm: React.FC<CAAFormProps> = ({ agents, isPending, onSubmit }) => {
  const form = useForm<CAAFormData>({
    resolver: zodResolver(CAASchema),
    defaultValues: {
      agent_id: "",
      actual_hospital: 0,
      active_hospitals: 0,
    },
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <FormField
          control={form.control}
          name="agent_id"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Select Agent</FormLabel>
              <ReactSelect
                placeholder="Select Agent"
                defaultValue={field.value}
                options={agents?.map((agent) => ({
                  value: agent.id,
                  label: agent.name,
                }))}
                onChange={field.onChange}
              />
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="active_hospitals"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                Expected Total Active Hospitals Monthly Target
              </FormLabel>
              <FormControl>
                <Input
                  placeholder="e.g. 5"
                  {...field}
                  value={field.value === null ? "" : field.value} // Empty string for null
                  onChange={(e) => {
                    const inputValue = e.target.value;

                    // If the input is empty, set value to null (so it can be reset)
                    if (inputValue === "") {
                      field.onChange(null);
                    } else {
                      const parsedValue = parseFloat(inputValue);

                      // Only update value if it is a valid number
                      if (!isNaN(parsedValue)) {
                        field.onChange(parsedValue);
                      }
                    }
                  }}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="actual_hospital"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Monthly Active Hospitals' Actual</FormLabel>
              <FormControl>
                <Input
                  placeholder="e.g. 10"
                  {...field}
                  value={field.value === null ? "" : field.value} // Empty string for null
                  onChange={(e) => {
                    const inputValue = e.target.value;

                    // If the input is empty, set value to null (so it can be reset)
                    if (inputValue === "") {
                      field.onChange(null);
                    } else {
                      const parsedValue = parseFloat(inputValue);

                      // Only update value if it is a valid number
                      if (!isNaN(parsedValue)) {
                        field.onChange(parsedValue);
                      }
                    }
                  }}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="flex justify-end pt-6">
          {isPending ? (
            <LoadingButton visible message="Please wait" />
          ) : (
            <Button type="submit">Set Targets</Button>
          )}
        </div>
      </form>
    </Form>
  );
};

export default CAAForm;
